import React from 'react'

const PrimaryButton = ({ text }) => {
    return (
        <button className='bg-[#22BDFF] rounded-3xl py-1 px-4 text-white font-semibold'>
            <p>{text}</p>
        </button>
    )
}

export default PrimaryButton
