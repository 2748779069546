import React from 'react'
import PrimaryButton from './Button/PrimaryButton'

const NewProductCard = () => {
    return (
        <div className='w-[17vw] shadow-center flex flex-col p-2 rounded-lg gap-3 py-5'>
            <p className='text-[#BC0000]'>Шинэ</p>
            <h2 className='text-[25px] font-semibold w-[15vw]'>Бизнес танилцуулга сайт</h2>
            <img className='w-[18vw] h-[8vw] shadow-center rounded-md' src="https://cdn5.vectorstock.com/i/1000x1000/90/34/web-development-project-team-webpage-building-vector-28959034.jpg" alt="" />
            <div className='flex justify-between items-center'>
                <p className='text-[13px] w-[12vw] text-[#838383]'>Бизнесээ өрөгжүүлэхэд тань тусална</p>
                <div className='text-[13px]'>
                    <PrimaryButton text={'Зочлох'} />
                </div>
            </div>
        </div>
    )
}

export default NewProductCard
