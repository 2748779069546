import React from 'react'
import HeroSection from '../../Components/Home/Hero'
import SpecialProduct from '../../Components/Home/SpecialProduct'
import InstructionSection from '../../Components/Home/Instruction'
import NewProduct from '../../Components/Home/NewProduct'
import CustomOrderSection from '../../Components/Home/CustomOrder'
import HelpCenter from '../../Components/Home/HelpCenter'

const Home = () => {
    return (
        <div className='p-10 w-[95vw] flex flex-col items-center justify-center gap-10 pt-20'>
            <HeroSection />
            <SpecialProduct />
            <InstructionSection />
            <NewProduct />
            <CustomOrderSection />
            <HelpCenter />
            <div className='h-[10vh]'></div>
        </div>
    )
}

export default Home
