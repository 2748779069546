import React from 'react';
import deal from '../../Assets/custom_order.png'
import SecondaryButton from '../../Utills/Button/SecondaryButton';

const CustomOrderSection = () => (
    <section className="flex w-[90vw] bg-[#22BDFF] text-white p-10 justify-around shadow-center rounded-xl">
        <div className='flex flex-col justify-between items-start w-[25vw]'>
            <h3 className='text-[30px] font-bold'>Ажлын захиалга өгөх</h3>
            <p>Та өөрийн хүссэн өнгө төрөх дизайны дагуу Аппликэшин, Вебсайтыг чанарын өндөр төвшинд захиалгаар манай баг хамт олноор хөгжүүлэх боломжтой</p>
            <div className='self-end'>
                <SecondaryButton text={'Зочлох'} />
            </div>
        </div>
        <img src={deal} className='w-[200px] h-[200px]' alt="" />
    </section>
);

export default CustomOrderSection;
