import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import SwiperCard from '../../Utills/SwiperCard';

const HeroSection = () => (
    <div className=''>
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
        >
            <SwiperSlide className=''>
                <SwiperCard />
            </SwiperSlide>
        </Swiper>
    </div>
);

export default HeroSection;
