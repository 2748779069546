import React from 'react'
import crown from '../../Assets/crown.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import ProductCard from '../../Utills/ProductCard';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const SpecialProduct = () => {
    return (
        <div className='flex w-[90vw] gap-5'>
            {/* <SwiperSlide className=''> */}
            <div className='bg-[#22BDFF] flex flex-col gap-5 rounded-xl w-[max-content] h-[300px] p-5 justify-center'>
                <h2 className='text-[30px] w-[200px] font-semibold text-center text-white'>Онцлох бүтээгдэхүүн</h2>
                <img src={crown} className='w-[120px] h-[90px] self-center' alt="" />
            </div>
            {/* </SwiperSlide> */}
            <Swiper
                spaceBetween={0}
                // className='w-[70vw]'
                slidesPerView={3}
                navigation={{ clickable: true }}  // Ensure navigation is enabled
                modules={[Navigation, Pagination, Scrollbar, A11y]} // Use all necessary modules
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                <SwiperSlide className=''><ProductCard /></SwiperSlide>
                <SwiperSlide className=''><ProductCard /></SwiperSlide>
                <SwiperSlide className=''><ProductCard /></SwiperSlide>
                <SwiperSlide className=''><ProductCard /></SwiperSlide>
                <SwiperSlide className=''><ProductCard /></SwiperSlide>
                <SwiperSlide className=''><ProductCard /></SwiperSlide>
                <SwiperSlide className=''><ProductCard /></SwiperSlide>
                <SwiperSlide className=''><ProductCard /></SwiperSlide>
                <SwiperSlide className=''><ProductCard /></SwiperSlide>
            </Swiper>
        </div>
    )
}

export default SpecialProduct
