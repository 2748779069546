import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { MdOutlineSearch } from "react-icons/md";
import { MdFavoriteBorder } from "react-icons/md";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { FiShoppingCart } from "react-icons/fi";

const Navbar = () => {
    const [currentPage, setCurrentPage] = useState(0);

    return (
        <div className='flex justify-center items-center w-screen fixed top-0 z-10 bg-white'>
            <div className='flex justify-between w-[90vw] self-center py-2'>
                <div className='flex gap-4'>
                    <Link className={currentPage === 0 ? 'active-nav' : ''} onClick={() => setCurrentPage(0)}>
                        Нүүр
                    </Link>
                    <Link className={currentPage === 1 ? 'active-nav' : ''} onClick={() => setCurrentPage(1)}>
                        Дэлгүүр
                    </Link>
                    <Link className={currentPage === 2 ? 'active-nav' : ''} onClick={() => setCurrentPage(2)}>
                        Үнэ
                    </Link>
                    <Link className={currentPage === 3 ? 'active-nav' : ''} onClick={() => setCurrentPage(3)}>
                        Холбоо барих
                    </Link>
                </div>
                <div className='flex gap-4 bg-[#333] items-center justify-center px-5 py-2 rounded-3xl'>
                    <Link><MdOutlineSearch size={30} color='#22BDFF' /></Link>
                    <Link><MdFavoriteBorder size={30} color='#22BDFF' /></Link>
                    <Link><HiOutlineUserCircle size={30} color='#22BDFF' /></Link>
                    <Link><FiShoppingCart size={30} color='#22BDFF' /></Link>
                </div>
            </div>
        </div>
    )
}

export default Navbar
