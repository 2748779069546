import React from 'react'
import HelpCenterCard from '../../Utills/HelpCenterCard'
import help1 from '../../Assets/help1.png';
import help2 from '../../Assets/help2.png';
import help3 from '../../Assets/help3.png';

const HelpCenter = () => {

    const datas = [
        {
            title: 'Алийг нь сонгохоо мэдэхгүй байвал',
            img: help1,
        },
        {
            title: 'Сонгосон багцаа сольхыг хүсвэл',
            img: help2,
        },
        {
            title: 'Холбоо барих, санал хүсэлт илгээхийг хүсвэл',
            img: help3,
        },
    ]
    return (
        <div className='flex justify-between w-[90vw]'>
            {datas.map((e) => (
                <HelpCenterCard img={e.img} text={e.title} />
            ))}
        </div>
    )
}

export default HelpCenter
