import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Navbar from './Components/Navbar/Navbar';

function App() {
  // const user = window.localStorage.getItem('userId');
  // const [partners, setPartners] = useState([]);
  // const userInfo = JSON.parse(window.localStorage.getItem('usersnk'));
  // const [isLoading, setIsLoading] = useState(true);
  // const [isNullOrder, setIsNullOrder] = useState(false);

  // useEffect(() => {
  //     if (isLoading && userInfo) {
  //         // alert('s');
  //         axios.get('http://localhost:8000/partner')
  //             .then((e) => { setPartners(e.data.data); setIsLoading(false) })
  //             .catch((e) => console.log(e));
  //     }
  //     if (userInfo === null && partners.length === 0) {
  //         // alert('a')
  //         setIsLoading(false)
  //     }

  //     if (userInfo !== null && partners.length === 0 && !isNullOrder) {
  //         setIsNullOrder(true);
  //         // alert('w')
  //         setIsLoading(true);
  //     }
  // }, [isLoading, partners]);


  return (
    <div className='flex justify-center items-center flex-col'>
      <Router>
        {/* {userInfo !== null ? <Sidebar /> : ''} */}
        <Navbar />
        {/* {isLoading ? <div className="loader-container"><div className='loader'></div></div> : */}
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
        {/* } */}
      </Router>
    </div>
  );
}

export default App;
