import React from 'react'

const SecondaryButton = ({ text }) => {
    return (
        <button className='bg-[#fff] shadow-center rounded-3xl py-1 px-4 text-[#22BDFF] font-semibold'>
            <p>{text}</p>
        </button>
    )
}

export default SecondaryButton
