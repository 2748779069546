import React from 'react'
import NewProductCard from '../../Utills/NewProductCard'
import SecondaryButton from '../../Utills/Button/SecondaryButton'

const NewProduct = () => {
    return (
        <div className='flex flex-col w-[90vw] items-center gap-5'>
            <div className='grid grid-cols-5 justify-between gap-5'>
                <NewProductCard />
                <NewProductCard />
                <NewProductCard />
                <NewProductCard />
                <NewProductCard />
                <NewProductCard />
                <NewProductCard />
                <NewProductCard />
            </div>
            <div className=''>
                <SecondaryButton text={'Бүгдийг үзэх'} />
            </div>
        </div>
    )
}

export default NewProduct
