import React from 'react';
import build from '../../Assets/build.png'
import PrimaryButton from '../../Utills/Button/PrimaryButton';

const InstructionSection = () => (
    <section className="flex w-[90vw] p-10 justify-around shadow-center rounded-xl">
        <img src={build} className='w-[200px] h-[200px]' alt="" />
        <div className='flex flex-col justify-between items-end w-[30vw]'>
            <h3 className='text-[30px] font-bold'>Хэрхэн вебсайт түрээслэх талаар мэдэхгүй байна уу?</h3>
            <p>Та түрээсэлсэн вебсайт дээрээ өөрийн бүтээгдэхүүн, компани, мэдээлэл, гэх мэт өөрийн хүссэн бүх зүйлээ нэмэх боломжтой </p>
            <div>
                <PrimaryButton text={'Зочлох'} />
            </div>
        </div>
    </section>
);

export default InstructionSection;
